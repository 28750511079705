<template>
  <div>
    <div
      style="height: calc(100vh - 145px); overflow-y: auto; overflow-x: hidden"
    >
      <MainAccount v-if="isActiveTab('main-account')" />
      <SubAccount v-else-if="isActiveTab('sub-account')" />
      <EMarketShop v-else-if="isActiveTab('e-market-shop')" />
      <ZaloZnsConfig v-else-if="isActiveTab('zalo-zns')" />
      <Warehouse v-else-if="isActiveTab('warehouse-config')" />
      <Bank v-else-if="isActiveTab('bank-config')" />
      <DeliveryConfig v-else-if="isActiveTab('delivery-config')" />
      <DefaultConfig v-else-if="isActiveTab('default-config')" />
      <template v-if="isShowPosConfig()">
        <PosList v-if="isActiveTab('post-list')" />
        <WorkingTimeList v-if="isActiveTab('working-time-list')" />
        <DayOffList v-if="isActiveTab('day-off-list')" />
      </template>
      <template v-if="isShowOfficeConfig()">
        <OfficeList v-if="isActiveTab('office-list')" />
        <OfficeWorkingTimeList v-if="isActiveTab('office-working-time-list')" />
      </template>
      <template v-if="isShowInvoiceConfig()">
        <!-- <TaxCode v-if="isActiveTab('tax-code')" /> -->
        <InvoiceConfig v-if="isActiveTab('invoice-config')" />
      </template>
    </div>
    <v-divider class="mt-2"></v-divider>
    <div
      class="pt-2 pb-4 pb-md-2 white-space-nowrap overflow-x-auto overflow-y-hidden"
    >
      <v-btn
        :outlined="!isActiveTab('main-account')"
        color="primary"
        small
        class="mr-2"
        @click="changeTab('main-account')"
      >
        {{ $t("labels.main_account") }}
      </v-btn>
      <v-btn
        :outlined="!isActiveTab('sub-account')"
        color="primary"
        small
        class="mr-2"
        @click="changeTab('sub-account')"
      >
        {{ $t("labels.employee") }}
      </v-btn>
      <v-btn
        :outlined="!isActiveTab('e-market-shop')"
        color="primary"
        small
        class="mr-2"
        @click="changeTab('e-market-shop')"
      >
        {{ $t("labels.e_commerce") }}
      </v-btn>
      <v-btn
        v-if="checkModule(['administration_zns'])"
        :outlined="!isActiveTab('zalo-zns')"
        color="primary"
        small
        class="mr-2"
        @click="changeTab('zalo-zns')"
      >
        {{ $t("labels.zalo_zns") }}
      </v-btn>
      <v-btn
        :outlined="!isActiveTab('warehouse-config')"
        color="primary"
        small
        class="mr-2"
        @click="changeTab('warehouse-config')"
      >
        {{ $t("labels.warehouse_1") }}
      </v-btn>
      <v-btn
        :outlined="!isActiveTab('bank-config')"
        color="primary"
        small
        class="mr-2"
        @click="changeTab('bank-config')"
      >
        {{ $t("labels.bank") }}
      </v-btn>
      <v-btn
        v-if="isShowDeliveryConfig()"
        :outlined="!isActiveTab('delivery-config')"
        color="primary"
        small
        class="mr-2"
        @click="changeTab('delivery-config')"
      >
        {{ $t("labels.delivery_config") }}
      </v-btn>
      <v-btn
        :outlined="!isActiveTab('default-config')"
        color="primary"
        small
        class="mr-2"
        @click="changeTab('default-config')"
      >
        {{ $t("labels.default_config") }}
      </v-btn>

      <template v-if="isShowPosConfig()">
        <v-btn
          :outlined="!isActiveTab('post-list')"
          color="warning"
          small
          class="mr-2"
          @click="changeTab('post-list')"
        >
          {{ $t("labels.pos") }}
        </v-btn>
        <v-btn
          :outlined="!isActiveTab('working-time-list')"
          color="warning"
          small
          class="mr-2"
          @click="changeTab('working-time-list')"
        >
          {{ $t("labels.working_time") }}
        </v-btn>
        <v-btn
          :outlined="!isActiveTab('day-off-list')"
          color="warning"
          small
          class="mr-2"
          @click="changeTab('day-off-list')"
        >
          {{ $t("labels.day_off") }}
        </v-btn>
      </template>

      <template v-if="isShowOfficeConfig()">
        <v-btn
          :outlined="!isActiveTab('office-list')"
          color="purple"
          small
          class="white--text mr-2"
          @click="changeTab('office-list')"
        >
          {{ $t("labels.office") }}
        </v-btn>
        <v-btn
          :outlined="!isActiveTab('office-working-time-list')"
          color="purple"
          small
          class="white--text mr-2"
          @click="changeTab('office-working-time-list')"
        >
          {{ $t("labels.working_time") }}
        </v-btn>
      </template>

      <template v-if="isShowInvoiceConfig()">
        <!-- <v-btn
          :outlined="!isActiveTab('tax-code')"
          color="teal"
          small
          class="white--text mr-2"
          @click="changeTab('tax-code')"
        >
          {{ $t("labels.tax_code") }}
        </v-btn> -->
        <v-btn
          :outlined="!isActiveTab('invoice-config')"
          color="teal"
          small
          class="white--text mr-2"
          @click="changeTab('invoice-config')"
        >
          {{ $t("labels.invoice_config") }}
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  components: {
    MainAccount: () => import("@/components/account/MainAccount"),
    SubAccount: () => import("@/components/account/SubAccount"),
    EMarketShop: () => import("@/components/account/EMarketShop"),
    DeliveryConfig: () => import("@/components/account/DeliveryConfig"),
    DefaultConfig: () => import("@/components/account/DefaultConfig"),
    Warehouse: () => import("@/components/account/Warehouse"),
    Bank: () => import("@/components/account/Bank"),
    ZaloZnsConfig: () => import("@/components/account/ZaloZnsConfig"),
    PosList: () => import("@/components/pos/PosList"),
    WorkingTimeList: () => import("@/components/pos/WorkingTimeList"),
    DayOffList: () => import("@/components/pos/DayOffList"),
    OfficeList: () => import("@/components/office/List"),
    OfficeWorkingTimeList: () => import("@/components/office/WorkingTimeList"),
    InvoiceConfig: () => import("@/components/invoice/Config"),
    // TaxCode: () => import("@/components/invoice/TaxCode"),
  },
  data: () => ({
    isLoading: false,
    tab: "main-account",
  }),
  computed: {},
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    isActiveTab(tab) {
      return this.tab === tab;
    },
    isShowPosConfig() {
      const customer = window.me && window.me.customer;
      return customer.is_active_pos;
    },
    isShowDeliveryConfig() {
      const customer = window.me && window.me.customer;
      return !customer.is_multiple_shop;
    },
    isShowOfficeConfig() {
      return this.checkModule(["administration_office"]);
    },
    isShowInvoiceConfig() {
      return this.checkModule(["administration_invoice"]);
    },
  },
};
</script>

<style scoped></style>
